import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/appContext';
import SessionService from '../services/SessionService';
import { minToSec, minToMs } from '../utils';

export function useSession() {
  const sessionService = new SessionService();

  const {
    startTime, setStartTime,
    duration, setDuration,
    timeLeft, setTimeLeft,
    timeIsUp, setTimeIsUp,
    showTimeNotice, setShowTimeNotice,
    timeNoticeChecked, setTimeNoticeChecked,
    completeNoticeChecked, setCompleteNoticeChecked,
    isModelCached, setIsModelCached
  } = useContext(AppContext);

  const handleSetDuration = (duration, callback) => {
    if (duration) {
      setDuration(duration);
      sessionService.saveSessionOptions(
        { duration: duration },
        minToSec(duration) + minToSec(10) // ttl => duration + 10 minutes
      );

      callback && callback();
    }
  }

  const handleSetStartTime = (time) => {
    setStartTime(time);
    sessionService.saveSessionOptions(
      { startTime: time },
      minToSec(duration) + minToSec(10) // ttl => duration + 10 minutes
    );
  }

  const handleSetTimeNoticeChecked = () => {
    setTimeNoticeChecked(true);
    sessionService.saveSessionOptions(
      { timeNoticeChecked: true },
      minToSec(duration) + minToSec(10)
    );

    setShowTimeNotice(false);
  }

  const handleSetCompleteNoticeChecked = (val) => {
    setCompleteNoticeChecked(val);
    sessionService.saveSessionOptions(
      { completeNoticeChecked: val },
      minToSec(duration) + minToSec(10)
    );
  }

  const handleRemoveSessionOptions = () => {
    sessionService.removeSessionOptions();
    setStartTime(null);
    setDuration(null);
    setTimeLeft(null);
    setTimeIsUp(null);
    setShowTimeNotice(false);
    setTimeNoticeChecked(false);
    setCompleteNoticeChecked(false);
  }

  useEffect(() => {
    if (!startTime || !duration) return;

    const interval = setInterval(() => {
      const fullDuration = duration * 60 * 1000;
      const currentTime = Date.now();
      const timeLeftVal = (startTime + fullDuration) - currentTime;

      if (timeLeftVal <= 0) {
        clearInterval(interval);
        setTimeIsUp(true);
      }
      else if (timeLeftVal <= minToMs(5)) {
        setShowTimeNotice(true);
      }
    }, 1000);


    return () => clearInterval(interval);
  }, [startTime, duration]);

  return {
    startTime, handleSetStartTime,
    duration, handleSetDuration,
    timeIsUp,
    timeLeft,
    showTimeNotice,
    timeNoticeChecked,
    handleRemoveSessionOptions,
    handleSetTimeNoticeChecked,
    completeNoticeChecked,
    handleSetCompleteNoticeChecked,
    isModelCached, setIsModelCached
  };
}