import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';

import { useOptions } from "../hooks/useOptions";
import { useSession } from "../hooks/useSession";

import Timer from "../components/Timer";
import IconSound from "../components/IconSound";
import GoalModal from "../components/GoalModal";
import WarningModal from "../components/WarningModal";

import s from '../assets/scss/pages/IdleView.module.scss';

import logo from '../assets/images/common/logo-header.svg';
import iconCamera from '../assets/images/common/button-camera.png';
import iconBook from '../assets/images/common/icon-book.svg';
import iconWorld from '../assets/images/common/icon-world.svg';

import selectSound from '../assets/audio/select.mp3';

export default function Idle() {
  const navigate = useNavigate();
  const location = useLocation();

  const [nextLocation, setNextLocation] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation('translation', {keyPrefix: 'idle'});
  const fromListPage = location.state && location.state.prevPage === '/zukan';

  const { isSoundOn, handleSetSound } = useOptions();
  const { timeNoticeChecked, showTimeNotice, timeIsUp } = useSession();

  const [playSelect] = useSound(selectSound, { soundEnabled: isSoundOn === "true" });
  const [playSelectNoOptions] = useSound(selectSound);

  const fadeAnim = {
    initial: { opacity: 0 },
    enter: { opacity: 1, transition: { ease: "linear", duration: 0.35, delay: fromListPage ? 0.35 : 0 } },
    exit: { opacity: 0, transition: { duration: 0.25 } }
  }

  useEffect(() => {
    if (timeIsUp) {
      navigate('/goal');
    }
  }, [timeIsUp]);

  const handleListClick = () => {
    setNextLocation('/zukan');
    playSelect();
  }

  const handleSoundBtnClick = e => {
    e.preventDefault();
    if (isSoundOn === "true") {
      handleSetSound('false');
    }
    else {
      handleSetSound('true');
      playSelectNoOptions();
    }
  }

  const handleGoalBtnClick = e => {
    e.preventDefault();
    setIsModalOpen(true);
    playSelect();
  }

  return (
    <>
      <motion.div
        className={s["page-idle"]}
        initial={{ opacity: 1 }}
        animate={ isModalOpen ? { opacity: 0 } : { opacity: 1 }}>
        <div className={`container ${s["container"]}`}>
          <motion.div
            className={s["top"]}
            initial={fadeAnim.initial}
            animate={fadeAnim.enter}
            exit={fadeAnim.exit}>
            <img src={logo} alt="Zukan Museum Niigata" />
          </motion.div>

          <motion.div
            className={s["mid"]}
            initial={fadeAnim.initial}
            animate={fadeAnim.enter}
            exit={fadeAnim.exit}>
            <div className={s["btn-camera"]}>
              <Link to="/camera" onClick={() => playSelect()}>
                <img src={iconCamera} alt="カメラモード起動" />
              </Link>
            </div>
          </motion.div>

          <div className={s["bottom"]}>
            <motion.div
              className={s["btnwrap"]}
              initial={fadeAnim.initial}
              animate={fadeAnim.enter}
              exit={fadeAnim.exit}>
              <div className={s["btn-goal"]}>
                <a href="#" onClick={e => handleGoalBtnClick(e)}><img src={iconWorld} alt="" /></a>
              </div>
              <div className={s["btn-sound"]}>
                <a href="#" onClick={e => handleSoundBtnClick(e)}>
                  <IconSound isSoundOn={ isSoundOn === "true" } />
                </a>
              </div>
            </motion.div>

            <motion.div
              className={s["btn"]}
              layoutId="zukanListBtn"
              initial={fromListPage ? {} : { opacity: 0 }}
              animate={fromListPage ? {} : { opacity: 1, transition: { delay: fromListPage ? 0.35 : 0 } }}
              exit={nextLocation === '/zukan' ? {} : { opacity: 0, transition: { duration: 0.35 } }}
              transition={{ layout: {ease: "easeOut", duration: 0.35} }}>
              <motion.div
                initial={fadeAnim.initial}
                animate={fadeAnim.enter}
                exit={fadeAnim.exit}>
                <Timer></Timer>
              </motion.div>
              <Link
                onClick={() => handleListClick()}
                to="/zukan"
                state={{ prevPage: location.pathname }}>
                <motion.img
                  src={iconBook}
                  alt=""
                  initial={{ opacity: fromListPage ? 0 : 1 }}
                  animate={{ opacity: 1, transition: { duration: 0.35, delay: fromListPage ? 0.35 : 0 } }}
                  exit={{ opacity: 0, transition: { duration: 0.15 } }} />
                <motion.span
                  layoutId="zukanListText"
                  initial={{ opacity: fromListPage ? 0 : 1 }}
                  animate={{ opacity: 1, transition: { duration: 0.35, delay: fromListPage ? 0.35 : 0 } }}
                  exit={{ opacity: 0, transition: { duration: 0.15 } }}>
                  { t('btn') }
                </motion.span>
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        { isModalOpen && <GoalModal onCloseClick={() => setIsModalOpen(false)} /> }
      </AnimatePresence>

      <AnimatePresence>
        { !timeNoticeChecked && showTimeNotice && <WarningModal></WarningModal> }
      </AnimatePresence>
    </>
  )
}