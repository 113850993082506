export const allAnimals = [
  {
    id: "1",
    creatureID: "cr001-siamang",
    name: "siamang",
    name_jp: "シアマン",
    image_url: "animals/animal-siamang.png"
  },
  {
    id: "2",
    creatureID: "cr002-fowl",
    name: "fowl",
    name_jp: "マレーウオミミズク",
    image_url: "animals/animal-fowl.png"
  },
  {
    id: "3",
    creatureID: "cr003-coati",
    name: "coati",
    name_jp: "アカハナグマ",
    image_url: "animals/animal-coati.png"
  },
  {
    id: "4",
    creatureID: "cr004-margay",
    name: "margay",
    name_jp: "マーゲイ",
    image_url: "animals/animal-margay.png"
  },
  {
    id: "11",
    creatureID: "cr011-moose",
    name: "moose",
    name_jp: "ヘラジカ",
    image_url: "animals/animal-moose.png"
  },
  {
    id: "12",
    creatureID: "cr012-bear",
    name: "bear",
    name_jp: "ハイイログマ",
    image_url: "animals/animal-bear.png"
  },
  {
    id: "15",
    creatureID: "cr015-bowl",
    name: "bowl",
    name_jp: "アナホリフクロウ",
    image_url: "animals/animal-bowl.png"
  },
  {
    id: "16",
    creatureID: "cr016-tamandua",
    name: "tamandua",
    name_jp: "ミナミコアリクイ",
    image_url: "animals/animal-tamandua.png"
  },
  {
    id: "23",
    creatureID: "cr023-jewel",
    name: "jewel",
    name_jp: "レッドジュエルシクリッド",
    image_url: "animals/animal-jewel.png"
  },
  {
    id: "24",
    creatureID: "cr024-catfish",
    name: "catfish",
    name_jp: "サカサナマズ",
    image_url: "animals/animal-catfish.png"
  },
  {
    id: "25",
    creatureID: "cr025-trout",
    name: "trout",
    name_jp: "カワマス",
    image_url: "animals/animal-trout.png"
  },
  {
    id: "26",
    creatureID: "cr026-mole",
    name: "mole",
    name_jp: "ホシバナモグラ",
    image_url: "animals/animal-mole.png"
  },
  {
    id: "33",
    creatureID: "cr033-dolphin",
    name: "dolphin",
    name_jp: "アマゾンカワイルカ",
    image_url: "animals/animal-dolphin.png"
  },
  {
    id: "34",
    creatureID: "cr034-arapaima",
    name: "arapaima",
    name_jp: "ピラルクー",
    image_url: "animals/animal-arapaima.png"
  },
  {
    id: "42",
    creatureID: "cr042-glizard",
    name: "glizard",
    name_jp: "アルマジロトカゲ",
    image_url: "animals/animal-glizard.png"
  },
  {
    id: "43",
    creatureID: "cr043-shrew",
    name: "shrew",
    name_jp: "ケープハネジネズミ",
    image_url: "animals/animal-shrew.png"
  },
  {
    id: "44",
    creatureID: "cr044-clizard",
    name: "clizard",
    name_jp: "トウブクビワトカゲ",
    image_url: "animals/animal-clizard.png"
  },
  {
    id: "45",
    creatureID: "cr045-snake",
    name: "snake",
    name_jp: "セイブダイヤガラガラヘビ",
    image_url: "animals/animal-snake.png"
  },
  {
    id: "46",
    creatureID: "cr046-gnu",
    name: "gnu",
    name_jp: "オグロヌー",
    image_url: "animals/animal-gnu.png"
  },
  {
    id: "47",
    creatureID: "cr047-hyena",
    name: "hyena",
    name_jp: "ブチハイエナ",
    image_url: "animals/animal-hyena.png"
  },
  {
    id: "52",
    creatureID: "cr052-oarfish",
    name: "oarfish",
    name_jp: "リュウグウノツカイ",
    image_url: "animals/animal-oarfish.png"
  },
  {
    id: "53",
    creatureID: "cr053-squid",
    name: "squid",
    name_jp: "ダイオウイカ",
    image_url: "animals/animal-squid.png"
  },
  {
    id: "54",
    creatureID: "cr054-fshark",
    name: "fshark",
    name_jp: "ラブカ",
    image_url: "animals/animal-fshark.png"
  },
  {
    id: "55",
    creatureID: "cr055-whale",
    name: "whale",
    name_jp: "マッコウクジラ",
    image_url: "animals/animal-whale.png"
  },
  {
    id: "51",
    creatureID: "cr051-panda",
    name: "panda",
    name_jp: "ジャイアントパンダ",
    image_url: "animals/animal-panda.png"
  },
  {
    id: "56",
    creatureID: "cr056-lionm",
    name: "lionm",
    name_jp: "ライオン",
    image_url: "animals/animal-lionm.png"
  },
  {
    id: "58",
    creatureID: "cr058-elephantf",
    name: "elephantf",
    name_jp: "アフリカゾウ",
    image_url: "animals/animal-elephantf.png"
  },
]