import { useEffect, useRef, useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as tf from '@tensorflow/tfjs';
import { loadGraphModel } from '@tensorflow/tfjs-converter';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, useMotionValue, useSpring } from 'framer-motion';

import { AppContext } from '../context/appContext';
import { useAnimals } from '../hooks/useAnimals';
import { useOptions } from '../hooks/useOptions';
import { useSession } from '../hooks/useSession';
import { minToMs } from '../utils';

import successSound from '../assets/audio/succeed.mp3';
import starSound from '../assets/audio/star.mp3';

import s from '../assets/scss/pages/CameraView.module.scss';

import imgFrame from '../assets/images/common/frame-white.png';
import iconCamera from '../assets/images/common/icon-camera.png';
import iconClose from '../assets/images/common/icon-close.svg';
import iconStone from '../assets/images/common/icon-record-stone.png';
import iconStone1 from '../assets/images/common/icon-record-stone-1.png';
import iconStone2 from '../assets/images/common/icon-record-stone-2.png';
import iconStone3 from '../assets/images/common/icon-record-stone-3.png';
import iconStone4 from '../assets/images/common/icon-record-stone-4.png';

const threshold = 0.65;

let localModel = null;

async function loadModel(callback) {
  try {
    let model;
    const modelName = 'zukan_niigata_model_3.0'; // <= Increment model version when updating a model
    const localModels = await tf.io.listModels();
    const idbModelUrl = `indexeddb://${modelName}`;
    const serverModelUrl = `${process.env.PUBLIC_URL}/models/zkn-efficientdet/model.json`;

    if ('indexedDB' in window) {
      for (const modelUrl of Object.keys(localModels)) {
        if (!modelUrl.includes(modelName)) {
          console.log('Removing unused models');
          await tf.io.removeModel(modelUrl);
        }
      }
    }

    if ('indexedDB' in window && idbModelUrl in localModels) {
      console.log('Loading model from indexedDB');
      model = await loadGraphModel(idbModelUrl);
      return model;
    }
    else {
      console.log('Loading model from server');
      model = await loadGraphModel(serverModelUrl);
      await model.save(idbModelUrl);
      return model;
    }

    // if (!localModel) {
    //   const model = await loadGraphModel(`${process.env.PUBLIC_URL}/models/zkn-efficientdet/model.json`);
    //   return model;
    // }
    // else {
    //   return localModel;
    // }
  } catch (error) {
    console.error('An error occurred while loading the model:', error);
    throw error;
  }
}

const classesDir = {
  1: { name: 'marker_hatena', id: 1, },
  2: { name: 'siamang', id: 2, },
  3: { name: 'fowl', id: 3, },
  4: { name: 'coati', id: 4, },
  5: { name: 'margay', id: 5, },
  6: { name: 'moose', id: 6, },
  7: { name: 'bear', id: 7, },
  8: { name: 'bowl', id: 8, },
  9: { name: 'tamandua', id: 9, },
  10: { name: 'panda', id: 10, },
  11: { name: 'jewel', id: 11, },
  12: { name: 'catfish', id: 12, },
  13: { name: 'trout', id: 13, },
  14: { name: 'mole', id: 14, },
  15: { name: 'dolphin', id: 15, },
  16: { name: 'arapaima', id: 16, },
  17: { name: 'oarfish', id: 17, },
  18: { name: 'squid', id: 18, },
  19: { name: 'fshark', id: 19, },
  20: { name: 'whale', id: 20, },
  21: { name: 'glizard', id: 21, },
  22: { name: 'shrew', id: 22, },
  23: { name: 'clizard', id: 23, },
  24: { name: 'snake', id: 24, },
  25: { name: 'gnu', id: 25, },
  26: { name: 'hyena', id: 26, },
  27: { name: 'lionm', id: 27, },
  28: { name: 'elephantf', id: 28, },
  29: { name: 'poster_kv', id: 29 }
}

function drawFrame(ctx, item) {
  // Font options.
  const font = "16px sans-serif";
  ctx.font = font;
  ctx.textBaseline = "top";

  const x = item['bbox'][0];
  const y = item['bbox'][1];
  const width = item['bbox'][2];
  const height = item['bbox'][3];

  // Draw the bounding box.
  ctx.strokeStyle = "#ffffff";
  ctx.lineWidth = 4;
  ctx.strokeRect(x, y, width, height);

  // Draw the label background.
  ctx.fillStyle = "#ffffff";
  const label = item["label"] + " " + (100 * item["score"]).toFixed(2) + "%";
  const textWidth = ctx.measureText(label).width;
  const textHeight = parseInt(font, 10); // base 10
  ctx.fillRect(x, y, textWidth + 4, textHeight + 4);

  // Draw label text.
  ctx.fillStyle = "#000000";
  ctx.fillText(label, x, y);
}

export default function Camera() {
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const { startTime, handleSetStartTime, isModelCached, setIsModelCached } = useSession();
  const { isSoundOn } = useOptions();
  const { caughtAnimalsList, addCaughtAnimal } = useAnimals();
  const [videoSize, setVideoSize] = useState({x: 390, y: 844});
  const [caughtAnimalName, setCaughtAnimalName] = useState('');
  const [markerDetected, setMarkerDetected] = useState(false);
  const [animalDetected, setAnimalDetected] = useState(false);
  const [isLoadOver, setIsLoadOver] = useState(false);
  const [showWarningOverlay, setShowWarningOverlay] = useState(false);
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const { forceReload } = useContext(AppContext);

  const { t } = useTranslation('translation', { keyPrefix: 'camera' });
  const [playSuccess] = useSound(successSound, { soundEnabled: isSoundOn === "true" });
  const [playStar] = useSound(starSound, { soundEnabled: isSoundOn === "true" });

  const loadingProgress = useMotionValue(0);
  const scaleX = useSpring(loadingProgress, {
    stiffness: 300,
    damping: 60,
  });

  useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        window.location.reload();
      }
    }

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  // Save start time on first animal detection
  // useEffect(() => {
  //   if (!startTime && animalDetected) {
  //     console.log('Saving start time...')
  //     handleSetStartTime(Date.now());
  //   }
  // }, [startTime, animalDetected])

  useEffect(() => {
    const messageTimer = setTimeout(() => setShowCloseMessage(true), minToMs(5));
    // const messageTimer = setTimeout(() => setShowCloseMessage(true), 20000);

    return () => clearTimeout(messageTimer);
  }, []);

  useEffect(() => {
    let currentStream = null;
    let animLoop = null;
    let model;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('0. Camera stream available')

      const modelPromise = loadModel();

      const webCamPromise = navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: { facingMode: "environment" }
      })
      .then(stream => {
        currentStream = stream;
        window.stream = stream;
        videoRef.current.srcObject = stream;
        loadingProgress.set(0.15);

        return new Promise((resolve, reject) => {
          videoRef.current.onloadedmetadata = () => {
            console.log('1. Camera stream loaded');
            loadingProgress.set(0.33);
            resolve();
          };
        });
      })
      .catch(error => {
        console.error(error);
      });

      Promise.all([modelPromise, webCamPromise])
      .then(values => {
          model = values[0];
          loadingProgress.set(0.67);

          // tf.env().set("WEBGL_DELETE_TEXTURE_THRESHOLD", 512000000);
          detectFrame();

          console.log('2. Model loaded')
        })
        .catch(error => {
          console.error(error);
        });
    }

    function detectFrame() {
      if (!videoRef.current || !model) return;

      tf.engine().startScope();
      model.executeAsync(processInput(videoRef.current)).then(predictions => {
        // if (!isModelCached || !localModel) {
          //   localModel = model;
          //   setIsModelCached(true);
          // }

          renderPredictions(predictions, videoRef.current);

          animLoop = setTimeout(() => detectFrame(), 1000);

          console.log('3. Animation loop running...');
          loadingProgress.set(1);
          setIsModelCached(true);
          setIsLoadOver(true);

          tf.engine().endScope();

          // console.log(tf.memory());
      });
    };

    return () => {
      // cancelAnimationFrame(animLoop);
      clearTimeout(animLoop);

      setTimeout(() => {
        tf.dispose(model);
        model = null;
        console.log('Tensor disposed');
      }, 1000);

      if (currentStream) {
        const tracks = currentStream.getTracks();
        tracks.forEach(track => track.stop());
        currentStream = null;
        window.stream = null;
        // videoRef.current.srcObject = null;
      }
    };
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;

        setVideoSize({ x: parseInt(width), y: parseInt(height) });
      }
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function processInput(video_frame) {
    // if (!video_frame) return;
    const tfimg = tf.browser.fromPixels(video_frame).toInt();
    const expandedimg = tfimg.transpose([0, 1, 2]).expandDims();
    return expandedimg;
  };

  function buildDetectedObjects(scores, boxes, classes, classesDir) {
    const detectedObjects = []
    const videoFrame = canvasRef.current;

    const th = threshold;
    scores[0].forEach((score, i) => {
      if (classes && classes[i] && score > th) {
        const bbox = [];
        const minY = boxes[0][i][0] * videoFrame.offsetHeight;
        const minX = boxes[0][i][1] * videoFrame.offsetWidth;
        const maxY = boxes[0][i][2] * videoFrame.offsetHeight;
        const maxX = boxes[0][i][3] * videoFrame.offsetWidth;
        bbox[0] = minX;
        bbox[1] = minY;
        bbox[2] = maxX - minX;
        bbox[3] = maxY - minY;


        detectedObjects.push({
          class: Math.round(classes[i]),
          label: classesDir[Math.round(classes[i])].name,
          score: score.toFixed(4),
          bbox: bbox
        });
      }
    })

    return detectedObjects;
  }

  function renderPredictions(predictions) {
    if (!canvasRef.current) return;
    // const ctx = canvasRef.current.getContext("2d");
    // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    let boxes, scores, classes;
    predictions.forEach((prediction, i) => {  // HACK: 毎フレームやらなくても初期化時に1度でよい
      const arr = prediction.arraySync();
      const pr = arr[0];

      if (Array.isArray(pr) && pr.length === 100) {
        if (Array.isArray(pr[0])) {
          if (pr[0].length === 4) { // WARNING: クラス数が4の時、誤って他のインデックスを指定する可能性がある
            boxes = prediction.arraySync();
          }
        } else if (Number.isInteger(pr[0])) {
          let isClass = true;
          for (let j = 0; j < pr.length; j++) {
            if (pr[j] > Object.keys(classesDir).length) {
              isClass = false;
              break;
            }
          }
          if (isClass) {
            classes = prediction.dataSync();
          }
        } else {
          scores = prediction.arraySync();
        }
      }
    })

    const detections = buildDetectedObjects(scores, boxes, classes, classesDir);
    const detectedMarker = detections && detections.find(item => item.label === 'marker_hatena');
    const detectedAnimal = detections && detections.find(item => item.label !== 'marker_hatena' && item.label !== 'poster_kv');

    // Only animal detected
    if (detectedAnimal) {
      setAnimalDetected(true);
    }
    else {
      setAnimalDetected(false);
    }

    // Animal & marker detected
    if (detectedMarker && detectedAnimal) {
      setCaughtAnimalName(detectedAnimal.label.toLowerCase());
      setMarkerDetected(true);
    }
    else {
      setMarkerDetected(false);
    }

    // Draw frame
    // detections.forEach(item => drawFrame(ctx, item));
  };

  const handlePageCloseClick = e => {
    if (forceReload) {
      e.preventDefault();
      window.location.reload();
    }
  }

  const handleLinkClick = e => {
    e.preventDefault();
    const isAnimalCaught = caughtAnimalsList.find(item => item.name === caughtAnimalName);

    if (!startTime) {
      console.log('Saving start time...')
      handleSetStartTime(Date.now());
    }

    if (isAnimalCaught) {
      setShowWarningOverlay(true);
    }
    else {
      addCaughtAnimal(caughtAnimalName);
      playSuccess();
      setTimeout(() => playStar(), 3750);
      navigate(`/zukan/${caughtAnimalName}`);
    }
  }

  const handleWarningOverlayClose = e => {
    e.preventDefault();
    setShowWarningOverlay(false);
  }

  return (
      <div className={s["page-camera"]}>
        <div className={s["container"]}>
          <motion.div
            className={s["btn-close"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: "linear" }}>
            <Link to="/idle" onClick={e => handlePageCloseClick(e)}><img src={iconClose} alt="" /></Link>
          </motion.div>

          <AnimatePresence>
            { !isModelCached && !isLoadOver && (
              <motion.div
                className={s["loading-overlay"]}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "linear", duration: 0.5 }}>
                <div className={s["icon"]}>
                  <img src={iconStone1} alt="" />
                  <img src={iconStone2} alt="" />
                  <img src={iconStone3} alt="" />
                  <img src={iconStone4} alt="" />
                </div>
                <div className={s["text"]}><p>{t("modal_loading")}</p></div>
                <div className={s["progress"]}>
                  <motion.span
                    initial={{ scaleX: 0 }}
                    style={{ scaleX, transformOrigin: "left" }}>
                  </motion.span>
                </div>
              </motion.div>
            ) }
          </AnimatePresence>

          <AnimatePresence>
            {showWarningOverlay && (
              <motion.div
                className={s["warning-overlay"]}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "linear", duration: 0.5 }}
                onClick={() => setShowWarningOverlay(false)}>
                <div className={s["btn-close"]}>
                  <a href="#" onClick={e => handleWarningOverlayClose(e)}><img src={iconClose} alt="" /></a>
                </div>
                <div className={s["image"]}><img src={iconStone} alt="" /></div>
                <div className={s["text"]}><p dangerouslySetInnerHTML={{ __html: t("modal_warning_title") }}></p></div>
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div
            className={`${s["warning-overlay"]} ${s["show-horizontal"]}`}>
            <div className={s["image"]}><img src={iconStone} alt="" /></div>
            <div className={s["text"]}><p dangerouslySetInnerHTML={{ __html: t("modal_horizontal_title") }}></p></div>
          </motion.div>

          <video
          style={{ opacity: (isModelCached || isLoadOver) ? 1 : 0 }}
          id="camera-stream"
          className={s["stream"]}
          width={videoSize.x}
          height={videoSize.y}
          ref={videoRef}
          autoPlay={true}
          playsInline={true}
          muted={true}>
          </video>

          <canvas
          className={s["c"]}
          style={{ width: `${videoSize.x}px`, height: `${videoSize.y}px` }}
          width={videoSize.x}
          height={videoSize.y}
          ref={canvasRef}>
          </canvas>

          <div className={`${s["btn-camera"]} ${markerDetected ? s["is-visible"] : ''}`}>
            <motion.div whileTap={{ scale: 1.15 }}>
              <Link
                to={`/zukan/${caughtAnimalName}`}
                onClick={e => handleLinkClick(e)}>
                <img src={iconCamera} alt="記録する" />
              </Link>
            </motion.div>
          </div>

          { (isModelCached || isLoadOver) && (
            <>
              <div
                className={`${s["frame"]} ${animalDetected ? s["is-visible"] : ''} ${markerDetected ? s["is-fixed"] : ''}`}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1, ease: "linear" }}>
                  <img src={imgFrame} alt="" />
                </motion.div>
              </div>

              <motion.div
                className={s["guide"]}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1, ease: "linear" }}>
                  <AnimatePresence mode="wait">
                    {markerDetected ? (
                      <motion.div key={1} className={s["guide__item"]} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25, ease: "linear" }}><p dangerouslySetInnerHTML={{ __html: t("guide_marker_detected") }}></p></motion.div>
                    ) :
                    animalDetected ? (
                      <motion.div key={2} className={s["guide__item"]} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25, ease: "linear" }}><p dangerouslySetInnerHTML={{ __html: t("guide_animal_detected") }}></p></motion.div>
                    ) :
                    (
                      <motion.div key={3} className={`${s["guide__item"]} ${s["guide__item--first"]}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.25, ease: "linear" }}><p dangerouslySetInnerHTML={{ __html: t("guide_default") }}></p></motion.div>
                    )}
                  </AnimatePresence>
              </motion.div>

              <motion.div
                className={`${s["guide"]} ${s["guide-bottom"]}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1, ease: "linear" }}>
                <AnimatePresence>
                  {!animalDetected && showCloseMessage && (
                    <motion.div
                      className={s["guide__item"]}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.25, ease: "linear" }}>
                      <p>カメラ画面のまま5分以上たちました。<br />一度メイン画面に戻りましょう。</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </>
          ) }
        </div>
      </div>
  )
}
