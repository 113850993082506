import { useEffect, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../context/appContext';
import OptionsService from '../services/OptionsService';
import { minToSec, minToMs } from '../utils';

export function useOptions() {
  const optionsService = new OptionsService();

  const {
    userId, setUserId,
    lang, setLang,
    isSoundOn, setSoundOn,
  } = useContext(AppContext);

  const handleSetUserId = (id) => {
    const uuid = uuidv4();

    setUserId(id ? id : uuid);
    optionsService.saveOptions({ userId: id ? id : uuid });
  }

  const handleSetLang = (lang) => {
    setLang(lang);
    optionsService.saveOptions({ lang: lang});
  }

  const handleSetSound = (sound) => {
    setSoundOn(sound);
    optionsService.saveOptions({isSoundOn: sound});
  }

  const handleRemoveOptions = () => {
    optionsService.removeOptions();
    setUserId(false);
    setSoundOn(null);
    setLang(null);
  }

  return { userId, lang, isSoundOn, handleSetUserId, handleSetLang, handleSetSound, handleRemoveOptions };
}