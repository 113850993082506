import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import { motion, useAnimate, AnimatePresence, useScroll, useMotionValueEvent } from 'framer-motion';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';

import { useAnimals } from '../hooks/useAnimals';
import { useOptions } from '../hooks/useOptions';

import RiveCanvas from '../components/RiveCanvas';
import Anchor from '../components/Anchor';

import selectSound from '../assets/audio/select.mp3';
import star2Sound from '../assets/audio/star_2.mp3';

import iconCorrect from '../assets/images/common/icon-answer-correct.svg';
import iconWrong from '../assets/images/common/icon-answer-wrong.svg';
import iconClose from '../assets/images/common/icon-close.svg';
import iconArrow from '../assets/images/common/icon-arrow.svg';

import s from '../assets/scss/pages/ZukanDetailView.module.scss';

export default function ZukanDetailView() {
  const location = useLocation();
  const imageRef = useRef();
  const fromListView = location.state && location.state.from && location.state.from === "listView";

  const [currentAnimal, setCurrentAnimal] = useState({});
  const [enterAnim, setEnterAnim] = useState(false);
  const [exitAnim, setExitAnim] = useState(false);
  const [answerOrder, setAnswerOrder] = useState(Math.random() > 0.5);
  const [answerClicked, setAnswerClicked] = useState(false);
  const [showQuiz, setShowQuiz] = useState(true);
  const [showScrollBtn, setShowScrollBtn] = useState(true);

  const { t } = useTranslation('translation', { keyPrefix: location.pathname.replace('/zukan/', '') });
  const description = t("description");
  const answerCorrect = t("quiz_answer_correct");
  const answerWrong = t("quiz_answer_wrong");

  const { scrollY } = useScroll();
  const { lang, isSoundOn } = useOptions();
  const { caughtAnimalsList, getAnimalByName, handleQuizAnswered } = useAnimals();

  const [playSelect] = useSound(selectSound, { soundEnabled: isSoundOn === "true" });
  const [playStar] = useSound(star2Sound, { soundEnabled: isSoundOn === "true" });

  const baseDelay = 1.75;
  const animDelays = {
    image: baseDelay + 0.1,
    canvasOut: baseDelay + 1.75,
    info: baseDelay + 2.75,
  };

  useEffect(() => {
    const currentPath = location.pathname.replace('/zukan/', '');
    const animalData = getAnimalByName(currentPath);
    setCurrentAnimal(animalData);
  }, [location]);

  useEffect(() => {
    const currentCaughtAnimal = caughtAnimalsList.find(item => item.name === currentAnimal.name);

    if (currentCaughtAnimal && currentCaughtAnimal.quizAnswered) {
      setShowQuiz(false);
    }
  }, [currentAnimal, caughtAnimalsList]);

  useEffect(() => {
    setShowScrollBtn((window.innerHeight + window.scrollY) < document.body.scrollHeight);
  }, []);

  useMotionValueEvent(scrollY, "change", latest => {
    // setShowScrollBtn(latest <= 50);
    setShowScrollBtn((window.innerHeight + latest + 10) <= document.body.scrollHeight);
  });

  const handleAnimStart = () => {
    setEnterAnim(true);
    setTimeout(() => {
      setExitAnim(true);
    }, animDelays.canvasOut * 1000);
  }

  const handleAnswerClick = e => {
    e.preventDefault();
    setAnswerClicked(true);
    playStar();
  }

  const handleCloseClick = () => {
    playSelect();

    if (answerClicked) {
      handleQuizAnswered(currentAnimal.name);
    }
  }

  return (
    <div className={s["page-detail"]}>
      { !fromListView && (
        <>
          <RiveCanvas enterAnim={enterAnim} exitAnim={exitAnim}></RiveCanvas>
          { currentAnimal && currentAnimal.image_url && (
            <motion.div
              ref={imageRef}
              className={s["image"]}
              initial={{ opacity: 0 }}
              animate={{
                opacity: [null, 1, 1, 0],
                transition: { duration: 2, times: [0, 0.2, 0.85, 1], delay: animDelays.image, ease: "linear" },
                transitionEnd: { display: 'none' }
              }}
              onAnimationStart={handleAnimStart}>
                <img src={`${process.env.PUBLIC_URL}/${currentAnimal.image_url}`} alt="" />
            </motion.div>
          ) }
        </>
      ) }

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { ease: "easeOut", duration: 0.5, delay: fromListView ? 0.25 : animDelays.info } }}>
        <AnimatePresence>
          {!showQuiz || answerClicked ? (
              <motion.div
                className={s["detail__close"]}
                initial={{ opacity: 0 }}
                animate={!showQuiz || answerClicked ? { opacity: 1 } : {}}
                transition={{ duration: 0.5, ease: "linear" }}>
                <Link to={fromListView ? "/zukan" : "/idle"} onClick={() => handleCloseClick()}>
                  <img src={iconClose} alt="" />
                </Link>
              </motion.div>
          ) : false}
        </AnimatePresence>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { ease: "easeOut", duration: 0.5, delay: fromListView ? 0.25 : animDelays.info } }}>
        <AnimatePresence>
          {showQuiz && showScrollBtn && (
              <motion.div
                className={s["detail__scroll"]}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "linear", duration: 0.35, delay: 0 }}>
                <Anchor target="#quiz">
                  <img src={iconArrow} alt="" />
                </Anchor>
              </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <div className={`container ${s["container"]}`}>
        <motion.div
          className={s["detail"]}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1, transition: { ease: "easeOut", duration: 0.5, delay: fromListView ? 0.25 : animDelays.info } }}>

          { currentAnimal && currentAnimal.image_url && (
            <figure className={s["detail__image"]}>
              <img src={`${process.env.PUBLIC_URL}/${currentAnimal.image_url}`} alt={currentAnimal.name_jp} />
            </figure>
          ) }
          <div className={s["detail__name"]}><p dangerouslySetInnerHTML={{ __html: t("name") }}></p></div>
          {t("kind") !== "" && <div className={s["detail__kind"]}><p>{t("kind")}</p></div>}
          {t("location") !== "" && <div className={s["detail__location"]}><p>{ lang === "jp_easy" ? "せいそくち" : "生息地" }：{t("location")}</p></div>}

          <div className={`${s["detail__desc"]} ${!showQuiz || answerClicked ? s["detail__desc--answered"] : ''}`}>
            <p dangerouslySetInnerHTML={{
              __html: description.replace(answerCorrect, `<div class="${s["detail__word"]}"><span>${"●".repeat(answerCorrect.length)}</span><span>${answerCorrect}</span></div>`)
            }}>
            </p>
          </div>
          <AnimatePresence>
            {showQuiz && (
              <motion.div
                id="quiz"
                className={s["detail__quiz"]}
                initial={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { delay: fromListView ? 0 : 0.75, ease: "linear" } }}>
                <div className={s["detail__quiz-q"]}><p>{"●".repeat(answerCorrect.length)}に入るものはなに？</p></div>
                <div
                  className={`${s["detail__quiz-wrap"]} ${answerClicked ? s["detail__quiz-wrap--answered"] : ''}`}
                  style={{ flexDirection: answerOrder ? 'row' : 'row-reverse' }}>
                  <motion.div
                    className={`${s["detail__quiz-a"]} ${s["detail__quiz-a--correct"]} ${answerCorrect.length > 2 ? s["detail__quiz-a--long"] : ''} ${answerWrong.length >= 10 ? s["detail__quiz-a--smaller"] : ''}`}
                    whileTap={{ scale: 1.1 }}>
                    <a href="#" onClick={e => handleAnswerClick(e)}>
                      <span className={s["layer-before"]}>
                        <span>{answerCorrect}</span>
                      </span>
                      <span className={s["layer-after"]}>
                        <img src={iconCorrect} alt="" />
                        <span>{answerCorrect}</span>
                      </span>
                    </a>
                  </motion.div>
                  <motion.div
                    className={`${s["detail__quiz-a"]} ${answerWrong.length > 2 ? s["detail__quiz-a--long"] : ''} ${answerWrong.length >= 10 ? s["detail__quiz-a--smaller"] : ''}`}
                    whileTap={{ scale: 1.1 }}>
                    <a href="#" onClick={e => handleAnswerClick(e)}>
                      <span className={s["layer-before"]}>
                        <span>{answerWrong}</span>
                      </span>
                      <span className={s["layer-after"]}>
                        <img src={iconWrong} style={{ left: '6px' }} alt="" />
                        <span>{answerWrong}</span>
                      </span>
                    </a>
                  </motion.div>
                  <motion.div
                    className={s["detail__particles"]}
                    initial={{ opacity: 0 }}
                    animate={answerClicked ? { opacity: 1 } : {}}>
                    {[...Array(40).keys()].map(index => (
                      <motion.div
                        key={index}
                        className={s["detail__particle"]}
                        initial={{ scale: 1 }}
                        animate={answerClicked ? { scale: 0, x: 200 - Math.random() * 400, y: 200 - Math.random() * 400, transitionEnd: { display: 'none' } } : {}}
                        transition={{ ease: "easeOut", duration: 1.5, delay: index * 0.01 }}>
                      </motion.div>
                    ))}
                  </motion.div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
}