import ls from 'localstorage-slim';

class SessionService {
  getSessionOptions() {
    const localData = ls.get('zukan-niigata-session');

    if (localData) {
      const localOptions = {
        userId: localData.userId,
        startTime: localData.startTime,
        duration: localData.duration,
        timeNoticeChecked: localData.timeNoticeChecked,
        completeNoticeChecked: localData.completeNoticeChecked
      };
      return localOptions;
    }
    else {
      return false;
    }
  }

  saveSessionOptions(opts, ttl) {
    const localData = ls.get('zukan-niigata-session');

    if (localData) {
      const data = { ...localData, ...opts };
      ls.set('zukan-niigata-session', data, { ttl: ttl });
    }
    else {
      const data = { ...opts };
      ls.set('zukan-niigata-session', data, { ttl: ttl });
    }

    console.log('Saving session options');
  }

  removeSessionOptions() {
    ls.remove('zukan-niigata-session');
  }

  //   /*
  //     `duration_${num}`
  //     ZHVyYXRpb25fMQ== => 1
  //     ZHVyYXRpb25fMzA=  =>  30
  //     ZHVyYXRpb25fNDA=  =>  40
  //     ZHVyYXRpb25fNTA=  =>  50
  //     ZHVyYXRpb25fNTM= => 53
  //     ZHVyYXRpb25fNjA=  =>  60
  //     ZHVyYXRpb25fNzA=  =>  70
  //     ZHVyYXRpb25fODA=  =>  80
  //     ZHVyYXRpb25fOTA=  =>  90
  //     ZHVyYXRpb25fMTAw  =>  100
  //     ZHVyYXRpb25fMTEw  =>  110
  //     ZHVyYXRpb25fMTIw  =>  120
  //     ZHVyYXRpb25fMTMw  =>  130
  //     ZHVyYXRpb25fMTQw  =>  140
  //     ZHVyYXRpb25fMTUw  =>  150
  //     ZHVyYXRpb25fMTYw  =>  160
  //     ZHVyYXRpb25fMTcw  =>  170
  //     ZHVyYXRpb25fMTgw  =>  180
  //   */

  // Use two separate functions!!
  getLocalDuration() {
    const sessionOpts = ls.get('zukan-niigata-session');
    return sessionOpts && sessionOpts.duration;
  }

  getDurationFromParams() {
    const params = new URL(window.location.toString()).searchParams;
    const durationParam = params.get('d');

    if (durationParam) {
      const durationB64 = atob(durationParam).replace("duration_", "");
      const durationVal = Number(durationB64) || false;

      return durationVal;
    }
    else {
      return false;
    }
  }
}

export default SessionService;
