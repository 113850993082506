import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/appContext';
import AnimalService from '../services/AnimalService';

export function useAnimals() {
  const animalService = new AnimalService();
  const {
    animalsList, setAnimalsList,
    caughtAnimalsList, setCaughtAnimalsList
  } = useContext(AppContext);

  const addCaughtAnimal = name => {
    const currentAnimal = {
      name: name,
      dateCaught: Date.now(),
      quizAnswered: false
    }

    const isCurrentAnimalInList = caughtAnimalsList.find(item => item.name === name);

    if (!isCurrentAnimalInList) {
      const newCaughtAnimalsList = caughtAnimalsList;
      newCaughtAnimalsList.push(currentAnimal);
      setCaughtAnimalsList(newCaughtAnimalsList);
      animalService.saveCaughtAnimals(newCaughtAnimalsList);
    }
  }

  const handleQuizAnswered = animalName => {
    const modifiedList = caughtAnimalsList.map(item => {
      if (item.name === animalName) {
        return { ...item, quizAnswered: true }
      }
      else {
        return item;
      }
    });

    setCaughtAnimalsList(modifiedList);
    animalService.saveCaughtAnimals(modifiedList);
  }

  const getAnimalByName = name => {
    const animal = animalsList.find(item => item.name === name.toLowerCase());
    return animal || {};
  }

  const handleRemoveAnimals = () => {
    animalService.removeCaughtAnimals();
    setCaughtAnimalsList([]);
  }

  const getCaughtAnimalIds = () => {
    const caughtAnimalIds = [];

    caughtAnimalsList.forEach(caughtAnimal => {
      const animalData = animalsList.find(item => item.name === caughtAnimal.name);

      if (animalData) {
        caughtAnimalIds.push(animalData.id);
      }
    });

    return caughtAnimalIds;
  }

  return {
    animalsList,
    caughtAnimalsList,
    addCaughtAnimal,
    getAnimalByName,
    handleQuizAnswered,
    handleRemoveAnimals,
    getCaughtAnimalIds
  };
}