import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import './i18n';
import ls from 'localstorage-slim';
import App from './App';
import AppProvider from './context/appContext';
import reportWebVitals from './reportWebVitals';
import './assets/scss/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppProvider>
    <MemoryRouter>
      <App />
    </MemoryRouter>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
