import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from './translations/main.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources: {
      jp: {
        translation: translations.jp
      },
      jp_easy: {
        translation: translations.jp_easy
      }
    },
    lng: 'jp',
    fallbackLng: 'jp',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;