import s from '../assets/scss/pages/InfoView.module.scss';

import heroImg from '../assets/images/info/hero_img.jpg';

export default function InfoView() {
  return (
    <div className={s["page-info"]}>
      <div className={s["hero"]}>
        <div className={s["hero__image"]}>
          <img src={heroImg} alt="ずかんミュージアム Zukan Museum Niigata" />
        </div>
        <div className={s["hero__textbox"]}>
          <div className={s["container"]}>
            <h1 className={s["hero__heading"]}>
              <span>ずかんミュージアムに行こう！</span>
              <small>新潟県民会館にて好評開催中</small>
            </h1>
            <p className={s["hero__text"]}>
              生き物のありのままの姿を記録しながら、<br />
              学び、楽しみ、夢中になれる<br />
              アカデミック・エンターテインメント。<br />
              この‟生きるミュージアム”で、世界中の<br />
              生き物たちが、あなたを待っている。
            </p>
          </div>
        </div>
      </div>

      <div className={s["container"]}>
        <div className={s["block"]}>
          <h2 className={s["block__title"]}>開催場所</h2>
          <address className={s["block__text"]}>
            新潟県民会館<br />
            〒951-8132<br />
            新潟県新潟市中央区一番堀通町3-13
          </address>
        </div>

        <div className={s["block"]}>
          <h2 className={s["block__title"]}>開催期間</h2>
          <p className={s["block__text"]}>
            2024年7月27日（土） ～ 9月29日（日）
          </p>
        </div>

        <div className={s["block"]}>
          <h2 className={s["block__title"]}>開場時間</h2>
          <p className={s["block__text"]}>
            土日祝<br />
            9：30～18：00<br />
            <br />
            平日<br />
            9：30～17：00　※7/29（月）～8/23（金）<br />
            13：00～17：00　※8/26（月）以降
          </p>
        </div>

        <div className={s["block"]}>
          <h2 className={s["block__title"]}>チケット</h2>
          <p className={s["block__text"]}>
            「アソビュー！」のみでの販売となります。<br />
            ご来場の日時・チケットの種類を指定して事前にご購入ください。<br />
            <br />
            土日祝<br />
            大人（高校生以上）2,000円<br />
            子ども（3歳～中学生）1,000円<br />
            <br />
            平日<br />
            大人（高校生以上）1,800円<br />
            子ども（3歳～中学生）800円
          </p>
        </div>

        <div className={s["block"]}>
          <h2 className={s["block__title"]}>注意事項</h2>
          <p className={s["block__text"]}>
            ※8/11(日)は新潟まつりのため16:00まで。(8/12予備日)<br />
            ※事前にチケットをご購入下さい。入場日時により完売の場合がございますのでご了承ください。<br />
            ※会場での入場券販売はございません。<br />
            ※指定された日時以外のご入場はできません。<br />
            ※３歳未満は入場無料ですが、ご入場には保護者（中学生以上の有料入場者）の同伴が必要です。<br />
            ※駐車場に限りがございます。なるべく公共交通機関をご利用いただき、お時間に余裕を持ってお越しください。
          </p>
          <small className={s["block__footnote"]}>主催／TeNYテレビ新潟、（公財）新潟市芸術文化振興財団
            後援／新潟県、新潟県教育委員会、新潟市、新潟市教育委員会</small>
        </div>
      </div>
    </div>
  );
}