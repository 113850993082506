export default function Anchor({ children, target, offset = 0 }) {
  const handleClick = e => {
    e.preventDefault();
    const scrollTargetEl = document.querySelector(target);

    if (scrollTargetEl) {
      window.scrollTo({ top: scrollTargetEl.offsetTop - offset, behavior: 'smooth' });
    }
  }

  return (
    <a href={target} onClick={handleClick}>{children}</a>
  )
}