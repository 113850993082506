import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import riveAnim from '../assets/animations/particleanim.riv';
import { useEffect } from 'react';

export default function RiveCanvas({ enterAnim, exitAnim }) {
  const { rive, RiveComponent } = useRive({
    src: riveAnim,
    stateMachines: "mainState",
    autoplay: true,
  });

  const enterInput = useStateMachineInput(rive, "mainState", "isEnter");
  const exitInput = useStateMachineInput(rive, "mainState", "isExit");
  // enterInput && enterInput.fire();

  useEffect(() => {
    if (rive && enterAnim && enterInput) {
      enterInput.fire();
    }
  }, [rive, enterInput, enterAnim]);

  useEffect(() => {
    if (rive && exitAnim && exitInput) {
      exitInput.fire();
    }
  }, [rive, exitInput, exitAnim]);


  return (
    <RiveComponent
      style={{ position: 'fixed', width: '100%', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none', zIndex: 5 }}
    />
  );
}