import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import { useAnimals } from '../hooks/useAnimals';
import { useOptions } from '../hooks/useOptions';
import { useSession } from '../hooks/useSession';

import s from '../assets/scss/pages/ZukanListView.module.scss'

import selectSound from '../assets/audio/select.mp3';

import iconStone from '../assets/images/common/icon-record-stone-white.png';
import iconClose from '../assets/images/common/icon-close.svg';
import completeBg from '../assets/images/common/bg-complete.jpg';

export default function ZukanListView() {
  const { t } = useTranslation('translation', { keyPrefix: 'zukan' });
  const location = useLocation();
  const { animalsList, caughtAnimalsList } = useAnimals();
  const { isSoundOn } = useOptions();
  const { completeNoticeChecked, handleSetCompleteNoticeChecked } = useSession();

  const [animalsRenderList, setAnimalsRenderList] = useState([]);
  const [nextPage, setNextPage] = useState('');
  const [allAnimalsCaught, setAllAnimalsCaught] = useState(false);

  const [play] = useSound(selectSound, { soundEnabled: isSoundOn === "true" });

  useEffect(() => {
    const renderList = [];

    caughtAnimalsList.forEach(caughtAnimal => {
      const animalData = animalsList.find(item => item.name === caughtAnimal.name);

      if (animalData) {
        renderList.push(animalData);
      }
    });

    setAnimalsRenderList(renderList);
  }, [caughtAnimalsList]);

  useEffect(() => {
    if (caughtAnimalsList.length === animalsList.length) {
      setAllAnimalsCaught(true);
    }
  }, [caughtAnimalsList]);

  const animVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.35, delay: 0.35 } }
  }

  const isAnimalCaught = animalName => {
    return !!caughtAnimalsList.find(item => item.name === animalName);
  }

  const handleLinkClick = (animalName) => {
    setNextPage(`/zukan/${animalName}`);
    play();
  }

  const handleCompleteCloseClick = (e) => {
    e.preventDefault();
    handleSetCompleteNoticeChecked(true);
  }

  return (
    <>
      <motion.div
        className={s["page-list"]}
        exit={nextPage.indexOf('zukan') > -1 ? { opacity: 0, transition: { duration: 0.35 } } : {}}>
        <div className="container">
          <motion.div
            className={s["head"]}
            layoutId="zukanListBtn"
            transition={{ layout: { ease: "easeOut", duration: 0.35 } }}>
            <motion.div
              className={s["head__text"]}
              initial={animVariants.initial}
              animate={animVariants.animate}>
              <p>
                {t('head_text')}
              </p>
              <span>
                {caughtAnimalsList.length}{t('head_span')} <b>/{animalsList.length}</b>
              </span>
            </motion.div>
            <motion.div
              className={s["head__btn"]}
              initial={animVariants.initial}
              animate={animVariants.animate}>
              <Link to="/idle" onClick={() => play()} state={{ prevPage: location.pathname }}><span></span><span></span></Link>
            </motion.div>
          </motion.div>

          <div className={s["body"]}>
            <motion.ul
              className={s["list"]}
              initial={animVariants.initial}
              animate={animVariants.animate}>
              {animalsRenderList.length ?
                animalsRenderList.map((item, index) => (
                  <motion.li
                    key={index}
                    className={s["list__item"]}
                    initial={{ opacity: 0, scale: 0.7 }}
                    animate={{ opacity: 1, scale: 1, transition: { ease: "backOut", duration: 0.75, delay: 0.25 + (index * 0.075) } }}>
                    <Link
                      to={`/zukan/${item.name}`}
                      onClick={() => handleLinkClick(item.name)}
                      state={{ from: 'listView' }}>
                      <img src={`${process.env.PUBLIC_URL}/${item.image_url}`} alt={item.name_jp} />
                    </Link>
                  </motion.li>
                )) : false
              }
            </motion.ul>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        { !completeNoticeChecked && allAnimalsCaught && (
          <motion.div
            className={s["complete-overlay"]}
            style={{ backgroundImage: `url(${completeBg})` }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.75 } }}
            exit={{ opacity: 0 }}
            transition={{ ease: "linear", duration: 0.5 }}>
            <div className={s["btn-close"]}>
              <a href="#" onClick={e => handleCompleteCloseClick(e)}><img src={iconClose} alt="" /></a>
            </div>
            <div className={s["image"]}><img src={iconStone} alt="" /></div>
            <div className={s["text"]}><p dangerouslySetInnerHTML={{ __html: t("complete_text") }}></p></div>
          </motion.div>
        ) }
      </AnimatePresence>
    </>
  );
}