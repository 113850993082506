import { motion } from 'framer-motion';
import { useSession } from '../hooks/useSession';

import s from '../assets/scss/components/WarningModal.module.scss';

import iconClose from '../assets/images/common/icon-close.svg';
import iconStone from '../assets/images/common/icon-record-stone.png';
import bgDark from '../assets/images/common/bg-dark.jpg';
import { useOptions } from '../hooks/useOptions';

export default function WarningModal() {
  const { lang } = useOptions();
  const { handleSetTimeNoticeChecked } = useSession();

  const handleCloseClick = e => {
    e.preventDefault();
    handleSetTimeNoticeChecked();
  }

  return (
    <motion.div
      className={s["modal"]}
      style={{ backgroundImage: `url(${bgDark})` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "linear", duration: 0.35 }}>
      <div className={s["modal__close"]}>
        <a href="#" onClick={e => handleCloseClick(e)}><img src={iconClose} alt="" /></a>
      </div>

      <div className={s["modal__main"]}>
        <div className={s["modal__image"]}><img src={iconStone} alt="" /></div>
        <div className={s["modal__text"]}>
          { lang === "jp_easy" ? (
            <p>
              あと5ふんで < br />
              きろくのいしが<br />つかえなくなります。<br />
              <br />
              ゴールにむかい、<br />
              きろくしたいきものを < br />
              ちきゅうにかえしましょう。
            </p>
          ) : (
              <p>
                あと5分で<br />
                記録の石が使えなくなります。<br />
                <br />
                ゴールに向かい、<br />
                記録した生き物を<br />
                地球に帰しましょう。
              </p>
          )}
        </div>
      </div>
    </motion.div>
  );
}

