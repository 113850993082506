import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import ls from 'localstorage-slim';
import useSound from 'use-sound';
import SessionService from "../services/SessionService";
import { useOptions } from "../hooks/useOptions";
import { useSession } from "../hooks/useSession";
import { useAnimals } from "../hooks/useAnimals";

import activateSound from '../assets/audio/activate.mp3';
import s from '../assets/scss/pages/SettingsView.module.scss';

import bg from '../assets/images/common/bg-index.jpg';
import logo from '../assets/images/common/logo-index.svg';
import iconClose from '../assets/images/common/icon-close.svg';
import bgModal from '../assets/images/common/bg-dark.jpg';

export default function Root() {
  const navigate = useNavigate();
  const sessionService = new SessionService();
  const { startTime, handleSetDuration, handleRemoveSessionOptions } = useSession();
  const { userId, lang, isSoundOn, handleSetUserId, handleRemoveOptions } = useOptions();
  const { handleRemoveAnimals, caughtAnimalsList } = useAnimals();
  const durationParamVal = useRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const durationParam = sessionService.getDurationFromParams();
    const durationLocal = sessionService.getLocalDuration();

    if (durationParam) {
      durationParamVal.current = durationParam;
      window.history.pushState({}, document.title, window.location.pathname);
      handleSetDuration(durationParamVal.current);

      if (!userId) {
        handleSetUserId();
      }

      // first time
      if (!lang || !isSoundOn || caughtAnimalsList.length === 0) {
        navigate('/settings');
        console.log('Setting duration from params');
      }
    }
    else if (durationLocal) {
      navigate('/settings');
    }
    else {
      navigate('/info');
    }
  }, []);

  const handleRestart = e => {
    e.preventDefault();
    handleRemoveSessionOptions();
    handleRemoveOptions();
    handleRemoveAnimals();
    handleSetDuration(durationParamVal.current);
    handleSetUserId();
    navigate('/settings');
  }

  const handleContinue = e => {
    e.preventDefault();
    handleRemoveSessionOptions();
    handleSetDuration(durationParamVal.current);
    navigate('/settings');
  }

  const handleModalOpenClick = e => {
    e.preventDefault();
    setShowModal(true);
  }

  const handleModalCloseClick = e => {
    e.preventDefault();
    setShowModal(false);
  }

  return (
    <>
      <div className={s["page-index"]} style={{ backgroundImage: `url(${bg})` }}>
        <div className={`container ${s["container"]}`}>
          <div className={s["logo"]}><img src={logo} alt="ずかんミュージアム Zukan Museum Niigata" /></div>
          <div className={s["select"]}>
            <motion.div
              className={s["select__block"]}
              key={'0'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <div className={s["select__options"]}>
                <div className={s["select__option"]}><a href="#" onClick={e => handleModalOpenClick(e)}>はじめから</a></div>
                <div className={s["select__option"]}><a href="#" onClick={e => handleContinue(e)}>つづきから</a></div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showModal && (
          <motion.div
            className={s["modal"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "linear", duration: 0.35 }}>
            <div className={s["modal__close"]}>
              <a href="#" onClick={e => handleModalCloseClick(e)}><img src={iconClose} alt="" /></a>
            </div>
            <div className={s["modal__text"]}>
              <p>これまでに捕まえた生き物のデータは削除されます。<br />
                「はじめから」やりなおしますか？</p>
            </div>
            <div className={s["modal__options"]}>
              <div className={s["modal__option"]}><a href="#" onClick={e => handleModalCloseClick(e)}>キャンセル</a></div>
              <div className={s["modal__option"]}><a href="#" onClick={e => handleRestart(e)}>はい</a></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}