import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';

import { useOptions } from '../hooks/useOptions';
import { useAnimals } from '../hooks/useAnimals';
import QrCodeCanvas from './QrCodeCanvas';

import s from '../assets/scss/components/GoalModal.module.scss';

import selectSound from '../assets/audio/select.mp3';

import iconClose from '../assets/images/common/icon-close.svg';
import iconBook from '../assets/images/common/icon-book.svg';
import goalImg from '../assets/images/goal/goal_img.png';



export default function GoalModal({ onCloseClick }) {
  const { t } = useTranslation('translation', { keyPrefix: 'goal_modal' });
  const { isSoundOn } = useOptions();
  const { getCaughtAnimalIds } = useAnimals();
  const [playSelect] = useSound(selectSound, { soundEnabled: isSoundOn === "true" });
  const [urlParam, setUrlParam] = useState('');

  useEffect(() => {
    const caughtAnimalIds = getCaughtAnimalIds();

    if (caughtAnimalIds.length) {
      setUrlParam(`?aid=${caughtAnimalIds.join()}`);
    }
  }, []);

  const handleCloseClick = e => {
    e.preventDefault();
    playSelect();
    onCloseClick && onCloseClick();
  }

  return (
    <motion.div
      className={s["modal"]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "linear", duration: 0.35 }}>
      <div className={s["modal__close"]}>
        <a href="#" onClick={e => handleCloseClick(e)}><img src={iconClose} alt="" /></a>
      </div>

      <div className={s["modal__head"]}>
        <div className={s["modal__image"]}>
          <img src={goalImg} alt="アースゾーン" />
        </div>
        <p className={s["modal__title"]} dangerouslySetInnerHTML={{ __html: t("title") }}></p>
      </div>

      <div className={s["modal__main"]}>
        <div className={s["modal__qr"]}>
          <div className={s["modal__qr-image"]}>
            <QrCodeCanvas />
          </div>
          <div className={s["modal__qr-text"]}>
            <p dangerouslySetInnerHTML={{ __html: t("text") }}></p>
          </div>
        </div>
      </div>

      <div className={s["modal__bottom"]}>
        <small dangerouslySetInnerHTML={{ __html: t("bottom") }}></small>

        <div className={s["btn"]}>
          <a href={`/my-zukan/${urlParam}`} target="_blank">
            <img src={iconBook} alt="" />
            <span>{t("btn")}</span>
          </a>
        </div>
      </div>
    </motion.div>
  );
}