/*
  Formats unix timestamp to hours:minutes:seconds
*/
export const formatTime = (timestamp) => {
  const formattedTime = {
    hours: Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timestamp % (1000 * 60)) / 1000)
  };

  return formattedTime;
}

/*
  Adds a leading zero if a number is less than 10
*/
export const padNumber = (num) => {
  if (num && !isNaN(num)) {
    return num < 10 ? `0${num}` : `${num}`;
  }
  else {
    return `00`;
  }
}

/*
  Converts minutes to seconds
*/
export const minToSec = (val) => {
  return Number.isInteger(val) && val * 60;
}

export const minToMs = (val) => {
  return val * 60 * 1000;
}

export const map = (num, inMin, inMax, outMin, outMax) => {
  return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

export const getTimeLeft = (startTime, duration) => {
  if (!startTime || !duration) return;

  const durationInMs = minToMs(duration);
  const timeLeft = (startTime + durationInMs) - Date.now();

  return timeLeft;
}