import { createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import ls from 'localstorage-slim';
import AnimalService from "../services/AnimalService";
import OptionsService from "../services/OptionsService";
import SessionService from "../services/SessionService";

export const AppContext = createContext();

export function AppProvider({ children }) {
  const animalService = new AnimalService();
  const optionsService = new OptionsService();
  const sessionService = new SessionService();
  const { i18n } = useTranslation();

  // Session opts
  const [userId, setUserId] = useState(getInitialOptions().userId);
  const [startTime, setStartTime] = useState(getSessionOptions().startTime);
  const [duration, setDuration] = useState(getSessionOptions().duration);
  const [timeLeft, setTimeLeft] = useState(null);
  const [timeIsUp, setTimeIsUp] = useState(null);
  const [completeNoticeChecked, setCompleteNoticeChecked] = useState(getSessionOptions().completeNoticeChecked);
  const [timeNoticeChecked, setTimeNoticeChecked] = useState(getSessionOptions().timeNoticeChecked);
  const [showTimeNotice, setShowTimeNotice] = useState(false);
  const [isModelCached, setIsModelCached] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  // Opts
  const [isSoundOn, setSoundOn] = useState(getInitialOptions().isSoundOn);
  const [lang, setLang] = useState(getInitialOptions().lang);
  // Animals
  const [animalsList, setAnimalsList] = useState(animalService.getAllAnimals());
  const [caughtAnimalsList, setCaughtAnimalsList] = useState(animalService.getCaughtAnimals());

  /*
  Get language and sound options from local storage
  */
  function getInitialOptions() {
    const res = {
      userId: false,
      isSoundOn: null,
      lang: null
    };

    const options = optionsService.getOptions();

    if (options) {
      if (options.userId) {
        res.userId = options.userId;
      }

      if (options.isSoundOn) {
        res.isSoundOn = options.isSoundOn;
      }

      if (options.lang) {
        res.lang = options.lang;
      }
    }

    return res;
  }


/*
Get session options (start time, duration) from local storage
*/
  function getSessionOptions() {
    const res = {
      startTime: null,
      duration: null,
      timeNoticeChecked: false,
      completeNoticeChecked: false
    };

    const sessionOptions = sessionService.getSessionOptions();
    const sessionDuration = sessionService.getLocalDuration();

    if (sessionOptions && sessionOptions.startTime) {
      res.startTime = sessionOptions.startTime;
    }

    if (sessionOptions && sessionOptions.timeNoticeChecked) {
      res.timeNoticeChecked = sessionOptions.timeNoticeChecked;
    }

    if (sessionOptions && sessionOptions.completeNoticeChecked) {
      res.completeNoticeChecked = sessionOptions.completeNoticeChecked;
    }

    if (sessionDuration) {
      res.duration = sessionDuration;
    }

    return res;
  }

  /*
  Change language
  */
  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    console.log('---------------------');
    console.log('startTime', startTime);
    console.log('duration', duration);
    console.log('timeLeft', timeLeft);
    console.log('timeIsUp', timeIsUp);
    console.log('showTimeNotice', showTimeNotice);
    console.log('timeNoticeChecked', timeNoticeChecked);
    console.log('completeNoticeChecked', completeNoticeChecked);
    console.log('isSoundOn', isSoundOn);
    console.log('lang', lang);
    console.log('animalsList', animalsList);
    console.log('caughtAnimalsList', caughtAnimalsList);
    console.log('isModelCached', isModelCached);
    console.log('---------------------');
  }, []);


  return (
    <AppContext.Provider value={{
      isModelCached, setIsModelCached,
      userId, setUserId,
      startTime, setStartTime,
      duration, setDuration,
      timeLeft, setTimeLeft,
      timeIsUp, setTimeIsUp,
      forceReload, setForceReload,
      showTimeNotice, setShowTimeNotice,
      timeNoticeChecked, setTimeNoticeChecked,
      completeNoticeChecked, setCompleteNoticeChecked,
      isSoundOn, setSoundOn,
      lang, setLang,
      animalsList, setAnimalsList,
      caughtAnimalsList, setCaughtAnimalsList
     }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider;