export default function IconSound({ isSoundOn }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19.5" stroke="white" />
      <mask id="path-2-inside-1_517_864" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M25 10L15.375 15H11V25H15.375L25 30V10Z" />
      </mask>
      <path d="M15.375 15V16C15.5355 16 15.6936 15.9614 15.836 15.8874L15.375 15ZM25 10H26C26 9.65067 25.8177 9.32666 25.5191 9.14531C25.2206 8.96396 24.849 8.95156 24.539 9.11259L25 10ZM11 15V14C10.4477 14 10 14.4477 10 15H11ZM11 25H10C10 25.5523 10.4477 26 11 26V25ZM15.375 25L15.836 24.1126C15.6936 24.0386 15.5355 24 15.375 24V25ZM25 30L24.539 30.8874C24.849 31.0484 25.2206 31.036 25.5191 30.8547C25.8177 30.6733 26 30.3493 26 30H25ZM15.836 15.8874L25.461 10.8874L24.539 9.11259L14.914 14.1126L15.836 15.8874ZM11 16H15.375V14H11V16ZM12 25V15H10V25H12ZM15.375 24H11V26H15.375V24ZM25.461 29.1126L15.836 24.1126L14.914 25.8874L24.539 30.8874L25.461 29.1126ZM24 10V30H26V10H24Z" fill="white" mask="url(#path-2-inside-1_517_864)" />
      <path style={{ opacity: isSoundOn ? 1 : 0 }} d="M31 14L27 17" stroke="white" strokeLinecap="round" />
      <path style={{ opacity: isSoundOn ? 1 : 0 }} d="M32 20.2688L27.1471 20.1888" stroke="white" strokeLinecap="round" />
      <path style={{ opacity: isSoundOn ? 1 : 0 }} d="M31.3498 25.5923L27.1872 23.0965" stroke="white" strokeLinecap="round" />
    </svg>
  );
}