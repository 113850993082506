import { useRef, useEffect } from "react";
import QRCode from 'qrcode';
import { useOptions } from "../hooks/useOptions";
import { useAnimals } from "../hooks/useAnimals";

export default function QrCodeCanvas() {
  const canvasRef = useRef();
  const { userId } = useOptions();
  const { animalsList, caughtAnimalsList } = useAnimals();

  useEffect(() => {
    if (!userId) return;

    const caughtAnimalIds = [];

    caughtAnimalsList.forEach(caughtAnimal => {
      const animalData = animalsList.find(item => item.name === caughtAnimal.name);

      if (animalData) {
        caughtAnimalIds.push(animalData.id);
      }
    });

    QRCode.toCanvas(
      canvasRef.current,
      `${userId}${caughtAnimalIds.length ? ',' : ''}${caughtAnimalIds.join()}`,
      { width: 240, margin: 25 },
      error => { if (error) console.log(`Error generating QR Code: ${error}`) }
    );
  }, [userId]);

  return (
    <canvas ref={canvasRef}></canvas>
  );
}