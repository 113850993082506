import { useEffect, useContext } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { AppContext } from './context/appContext';
import { AnimatePresence } from "framer-motion";

import RootView from "./routes/RootView";
import SettingsView from './routes/SettingsView';
import IdleView from "./routes/IdleView";
import CameraView from "./routes/CameraView";
import ZukanListView from "./routes/ZukanListView";
import ZukanDetailView from "./routes/ZukanDetailView";
import GoalView from "./routes/GoalView";
import InfoView from './routes/InfoView';
import PageTransitionWrap from "./components/PageTransitionWrap";

import { minToMs } from "./utils";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { forceReload, setForceReload } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      setForceReload(true);
      console.log('Set force reload');
    }, minToMs(5));
  }, []);

  return (
    <div className="app">
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<PageTransitionWrap><RootView /></PageTransitionWrap>}></Route>
          <Route path="/settings" element={<PageTransitionWrap><SettingsView /></PageTransitionWrap>}></Route>
          <Route path="/idle" element={<IdleView />}></Route>
          <Route path="/camera" element={<PageTransitionWrap><CameraView /></PageTransitionWrap>}></Route>
          <Route path="/zukan" element={<ZukanListView />}></Route>
          <Route path="/zukan/:animalName" element={<PageTransitionWrap><ZukanDetailView /></PageTransitionWrap>}></Route>
          <Route path="/goal" element={<PageTransitionWrap><GoalView /></PageTransitionWrap>}></Route>
          <Route path="/info" element={<PageTransitionWrap><InfoView /></PageTransitionWrap>}></Route>
        </Routes>
      </AnimatePresence>
      <div className="bg"></div>
    </div>
  )
}