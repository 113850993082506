import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useAnimals } from '../hooks/useAnimals';

import QrCodeCanvas from "../components/QrCodeCanvas";
import Timer from "../components/Timer";

import s from '../assets/scss/pages/GoalView.module.scss';

import logo from '../assets/images/common/logo-header.svg';
import iconBook from '../assets/images/common/icon-book.svg';
import goalImg from '../assets/images/goal/goal_img.png';

export default function GoalView() {
  const { t } = useTranslation('translation', { keyPrefix: 'goal' });
  const { getCaughtAnimalIds } = useAnimals();
  const [urlParam, setUrlParam] = useState('');

  useEffect(() => {
    const caughtAnimalIds = getCaughtAnimalIds();

    if (caughtAnimalIds.length) {
      setUrlParam(`?aid=${caughtAnimalIds.join()}`);
    }
  }, []);

  return (
    <div className={`${s["page-goal"]} ${s["goal"]}`}>
      <div className={`container ${s["goal__container"]}`}>
        <div className={s["goal__top"]}><img src={logo} alt="Zukan Museum Niigata" /></div>

        <div className={s["goal__head"]}>
          <div className={s["goal__image"]}>
            <img src={goalImg} alt="アースゾーン" />
          </div>
          <p className={s["goal__title"]} dangerouslySetInnerHTML={{ __html: t("title") }}></p>
        </div>

        <div className={s["goal__main"]}>
          <div className={s["goal__qr"]}>
            <div className={s["goal__qr-image"]}>
              <QrCodeCanvas />
            </div>
            <div className={s["goal__qr-text"]}>
              <p dangerouslySetInnerHTML={{ __html: t("text") }}>
              </p>
            </div>
            <div className={s["goal__qr-small"]}>
              <small dangerouslySetInnerHTML={{ __html: t("bottom") }}></small>
            </div>
          </div>
        </div>

        <div className={s["goal__bottom"]}>
          <div className={s["btn"]}>
            <Timer isFinished={true}></Timer>
            <a href={`/my-zukan/${urlParam}`} target="_blank">
              <img src={iconBook} alt="" />
              <span>{t("btn")}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}