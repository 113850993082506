import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import ls from 'localstorage-slim';
import useSound from 'use-sound';
import SessionService from "../services/SessionService";
import { useOptions } from "../hooks/useOptions";
import { useSession } from "../hooks/useSession";
import { useAnimals } from "../hooks/useAnimals";

import s from '../assets/scss/pages/SettingsView.module.scss';

import activateSound from '../assets/audio/activate.mp3';

import bg from '../assets/images/common/bg-index.jpg';
import logo from '../assets/images/common/logo-index.svg';

export default function SettingsView() {
  const navigate = useNavigate();
  const { lang, isSoundOn, handleSetLang, handleSetSound } = useOptions();
  const { startTime, handleSetStartTime, handleSetDuration } = useSession();
  const { caughtAnimalsList } = useAnimals();

  const [currentQuestion, setCurrentQuestion] = useState('0');
  const [play] = useSound(activateSound);

  useEffect(() => {
    if (lang && isSoundOn) {
      // if (!startTime) {
      //   handleSetStartTime(Date.now());
      // }

      navigate('/idle');
    }

    if (lang) {
      setCurrentQuestion('1');
    }
  }, [lang, isSoundOn]);

  const handleLangSelectionClick = (e, langSelection) => {
    e.preventDefault();
    handleSetLang(langSelection);
  }

  const handleSoundSelectionClick = (e, soundSelection) => {
    e.preventDefault();
    handleSetSound(soundSelection);
    soundSelection === 'true' && play();
    // handleSetStartTime(Date.now());
  }

  return (
    <div className={s["page-index"]} style={{ backgroundImage: `url(${bg})` }}>
      <div className={`container ${s["container"]}`}>
        <motion.div
          className={s["logo"]}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5, delay: 1.5, ease: [0.33, 1, 0.68, 1] }}>
          <img src={logo} alt="ずかんミュージアム Zukan Museum Niigata" />
        </motion.div>
        <motion.div
          className={s["select"]}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5, delay: 2, ease: [0.33, 1, 0.68, 1] }}>
          <AnimatePresence initial={false} mode="wait">
            {currentQuestion === '0' && (
              <motion.div
                className={s["select__block"]}
                key={'0'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className={s["select__text"]}><p>言語を選択してください</p></div>
                <div className={s["select__options"]}>
                  <div className={s["select__option"]}><a href="#" onClick={e => handleLangSelectionClick(e, 'jp')}>漢字</a></div>
                  <div className={s["select__option"]}><a href="#" onClick={e => handleLangSelectionClick(e, 'jp_easy')}>ひらがな</a></div>
                </div>
              </motion.div>
            )}

            {currentQuestion === '1' && (
              <motion.div
                className={s["select__block"]}
                key={'1'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className={s["select__text"]}><p>音をつけますか？</p></div>
                <div className={`${s["select__options"]} ${s["select__options--hor"]}`}>
                  <div className={`${s["select__option"]} ${s["select__option--round"]}`}><a href="#" onClick={e => handleSoundSelectionClick(e, 'true')}>ON</a></div>
                  <div className={`${s["select__option"]} ${s["select__option--round"]}`}><a href="#" onClick={e => handleSoundSelectionClick(e, 'false')}>OFF</a></div>
                </div>
                <small>※スマートフォンのミュートをオンにしてる場合は音が出ません</small>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      <motion.div
        className={s["anim-overlay"]}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 1.75, delay: 0.1, ease: "linear" }}>
      </motion.div>
    </div>
  )
}