import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

export default function PageTransitionWrap({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25, ease: "linear" }}
      className="transition-wrap">
      {children}
    </motion.div>
  )
}