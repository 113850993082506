import ls from 'localstorage-slim';

// settings => 1 week
// timeOpts => 1 hour
// animals => 1 week


const t = {
  ONE_HOUR: 60 * 60,
  ONE_DAY: 60 * 60 * 24,
  ONE_WEEK: 60 * 60 * 24 * 7
};
class OptionsService {
  getOptions() {
    const localData = ls.get('zukan-niigata-opts');

    if (localData) {
      const localOptions = {
        userId: localData.userId,
        lang: localData.lang,
        isSoundOn: localData.isSoundOn,
      };

      return localOptions;
    }
    else {
      return false;
    }
  }

  saveOptions(opts) {
    const localData = ls.get('zukan-niigata-opts');

    if (localData) {
      const data = { ...localData, ...opts };
      ls.set('zukan-niigata-opts', data, { ttl: t.ONE_WEEK });
    }
    else {
      const data = { ...opts };
      ls.set('zukan-niigata-opts', data, { ttl: t.ONE_WEEK });
    }
  }

  removeOptions() {
    ls.remove('zukan-niigata-opts');
  }
}

export default OptionsService;
