import React, { useEffect, useState, useRef } from 'react';
import { animate, motion, useTransform, useMotionValue } from 'framer-motion';
import { useSession } from '../hooks/useSession';
import { formatTime, padNumber, map, minToMs, getTimeLeft } from '../utils';
import iconClock from '../assets/images/common/icon-clock.png';
import iconArrow from '../assets/images/common/icon-clock-arrow.png';

import s from '../assets/scss/components/Timer.module.scss';

export default function Timer({ isFinished = false }) {
  const { startTime, duration } = useSession();
  const arrowRef = useRef(null);
  const bgRef = useRef(null);

  function setInitialRotation() {
    const timeLeftVal = getTimeLeft(startTime, duration);

    if (timeLeftVal > 0) {
      const animVal = map(
        timeLeftVal,
        minToMs(duration), 0,
        0, 360
      );

      const r = 14.5;
      const d = r * 2;
      const circ = d * Math.PI;
      const dashOffsetVal = map(
        timeLeftVal,
        minToMs(duration), 0,
        circ, 0
      );

      animate(arrowRef.current, { rotate: parseFloat(animVal.toFixed(2)) }, { duration: 0, ease: "linear" });
      animate(bgRef.current, { strokeDashoffset: parseFloat(dashOffsetVal.toFixed(2)) }, { duration: 0, ease: "linear" });
    }
  }

  useEffect(() => {
    if (!arrowRef.current || !bgRef.current || !startTime || !duration) return;
    setInitialRotation();

    const interval = setInterval(() => {
      const timeLeftVal = getTimeLeft(startTime, duration);

      if (timeLeftVal <= 0) {
        clearInterval(interval);
      }
      else {
        const rotateVal = map(
          timeLeftVal,
          minToMs(duration), 0,
          0, 360
        );

        const r = 14.5;
        const d = r * 2;
        const circ = d * Math.PI;
        const dashOffsetVal = map(
          timeLeftVal,
          minToMs(duration), 0,
          circ, 0
        )

        animate(arrowRef.current, { rotate: parseFloat(rotateVal.toFixed(2)) }, { duration: 0.1, ease: "linear" });
        animate(bgRef.current, { strokeDashoffset: parseFloat(dashOffsetVal.toFixed(2)) }, { duration: 0.1, ease: "linear" });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [arrowRef.current, bgRef.current, duration, startTime]);

  return (
    <motion.div
      className={s["timer"]}>
      <motion.div
        ref={arrowRef}
        className={s["timer__arrow"]}>
          <img src={iconArrow} alt="" />
      </motion.div>
      <div className={s["timer__bg"]} style={ isFinished || !startTime ? { display: 'none' } : {} }>
        <motion.svg height="58" width="58" viewBox="0 0 58 58">
          <motion.circle
            ref={bgRef}
            id={s["timer-circle"]} r="14.5" cx="29" cy="29" fill="transparent" stroke="#273575" />
        </motion.svg>
      </div>
      <div className={s["timer__base"]}><img src={iconClock} alt="" /></div>
    </motion.div>
  )
}