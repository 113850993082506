import ls from 'localstorage-slim';
import { allAnimals } from "./AnimalsData";

class AnimalService {
  getCaughtAnimals() {
    const localData = ls.get('zukan-niigata-animals');

    return localData || [];
    // if (localData) {
    //   return localData;
    // }
    // else {
    //   return [];
    // }
  }

  getAllAnimals() {
    return allAnimals;
  }

  saveCaughtAnimals(list) {
    const localData = ls.get('zukan-niigata-animals');
    const ONE_DAY = 24 * 60 * 60;
    const ONE_WEEK = ONE_DAY * 7;

    ls.set('zukan-niigata-animals', list, { ttl: ONE_WEEK });
  }

  removeCaughtAnimals() {
    ls.remove('zukan-niigata-animals');
  }
}

export default AnimalService;
